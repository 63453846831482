import React from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

const Join = () => {
    return (
        <div className="flex flex-col min-h-screen">
            {/* Header */}
            <Header />

            {/* Hero Section */}
            <section className="relative bg-cover bg-center h-96 flex flex-col justify-center items-center text-white" style={{ backgroundImage: "url('https://via.placeholder.com/1920x600')" }}>
                <h1 className="text-4xl md:text-5xl font-bold">Join Us</h1>
                <p className="text-lg md:text-xl mt-4 text-center">
                    We meet every other Saturday at 6:30 PM.
                </p>
            </section>

            {/* Details Section */}
            <section className="py-16 px-4">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">Come and Be Part of Our Fellowship</h2>
                    <p className="text-gray-600 mt-4 text-lg">
                        We gather in a welcoming and friendly environment to worship, share, and grow together in Christ.
                    </p>

                    <div className="mt-8 bg-gray-100 p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-bold text-gray-800">Meeting Details</h3>
                        <p className="mt-2 text-gray-700">
                            <strong>When:</strong> Every other Saturday at 6:30 PM
                        </p>
                        <p className="mt-2 text-gray-700">
                            <strong>Where:</strong> 123 Fellowship Street, Hometown, USA
                        </p>
                        <p className="mt-2 text-gray-700">
                            <strong>Contact:</strong> John Doe - (555) 123-4567
                        </p>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Join;