import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    
    const goToAbout = () => {
        navigate("/about");
    }
    
    const goToJoin = () => {
        navigate("/join");
    }

    const goToEvents = () => {
        navigate("/events");
    }

    const goToMinistries = () => {
        navigate("/ministries");
    }

    const goToConnect = () => {
        navigate("/connect");
    }

    return (
        <nav className="bg-gray-800 text-gray-200 py-6 px-6 flex justify-between items-center sticky top-0 z-10">
            <div className="text-xl font-bold">Forever His</div>
            <ul className="hidden md:flex space-x-6">
                <li><a href="#" className="hover:text-blue-400 transition"
                onClick={goToAbout}>About</a></li>
                <li><a href="#" className="hover:text-blue-400 transition"
                onClick={goToJoin}>Join</a></li>
                <li><a href="#" className="hover:text-blue-400 transition"
                onClick={goToEvents}>Events</a></li>
                <li><a href="#" className="hover:text-blue-400 transition"
                onClick={goToMinistries}>Ministries</a></li>
                <li><a href="#" className="hover:text-blue-400 transition"
                onClick={goToConnect}>Connect</a></li>
            </ul>
            <button className="md:hidden bg-blue-500 px-4 py-2 rounded hover:bg-blue-600">Menu</button>
        </nav>
    );
};

export default Header;