import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Join from "./pages/Join";
import Events from "./pages/Events";
import Ministries from "./pages/Ministries";
import Connect from "./pages/Connect";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <Routes>
            <Route
              path="/"
              element={<Home/>}
            />
            <Route
              path="/about"
              element={<About/>}
            />
            <Route
              path="/join"
              element={<Join/>}
            />
            <Route
              path="/events"
              element={<Events/>}
            />
            <Route
              path="/ministries"
              element={<Ministries/>}
            />
            <Route
              path="/connect"
              element={<Connect/>}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
