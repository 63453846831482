import { motion } from 'framer-motion';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import React, { useState } from 'react';

const Connect = () => {
    const contacts = [
        {
            name: "John Doe",
            phone: "(555) 123-4567",
            email: "john.doe@example.com",
        },
        {
            name: "Jane Smith",
            phone: "(555) 987-6543",
            email: "jane.smith@example.com",
        },
    ];

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you could send formData to your backend API
        alert("Thank you for reaching out! We will get back to you soon.");
        setFormData({ fullName: "", email: "", message: "" });
    };

    return (
        <div className="contact-us-page">
            {/* Header */}
            <Header />

            {/* Hero Section */}
            <section className="relative bg-cover bg-center h-96 flex flex-col justify-center items-center text-white" style={{ backgroundImage: "url('https://via.placeholder.com/1920x600')" }}>
                <h1 className="text-4xl md:text-5xl font-bold">Contact Us</h1>
                <p className="text-lg md:text-xl mt-4 text-center">
                    We're here to help. Get in touch with us!
                </p>
            </section>

            {/* Contact Details */}
            <section className="py-16 px-4">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-2xl md:text-3xl font-semibold text-center text-gray-800">Our Key Contacts</h2>
                    <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
                        {contacts.map((contact, index) => (
                            <div key={index} className="bg-white shadow rounded-lg p-6">
                                <h3 className="text-xl font-bold text-gray-800">{contact.name}</h3>
                                <p className="text-gray-800 mt-2">
                                    <strong>Phone:</strong> {contact.phone}
                                </p>
                                <p className="text-gray-800">
                                    <strong>Email:</strong> {contact.email}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Contact Form */}
            <section className="py-16 px-4 bg-gray-100">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-2xl md:text-3xl font-semibold text-center text-gray-800">Reach Out to Us</h2>
                    <form onSubmit={handleSubmit} className="mt-8 bg-white p-6 rounded-lg shadow-md">
                        <div className="mb-4">
                            <label htmlFor="fullName" className="block text-gray-700 font-medium mb-2">Full Name</label>
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded px-4 py-2"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded px-4 py-2"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="message" className="block text-gray-700 font-medium mb-2">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="5"
                                className="w-full border border-gray-300 rounded px-4 py-2"
                                required
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                        >
                            Send Message
                        </button>
                    </form>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Connect;