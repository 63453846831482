import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-gray-200 py-8 text-center mt-auto">
            <p className="text-sm">&copy; 2024 Forever His. All Rights Reserved.</p>
        </footer>
    );
};

export default Footer;