import React from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

const About = () => {
    return (
        <div className="about-us">
            {/* Header */}
            <Header />

            {/* Hero Section */}
            <section className="relative bg-cover bg-center h-96 flex flex-col justify-center items-center text-white" style={{ backgroundImage: "url('https://via.placeholder.com/1920x600')" }}>
                <motion.h1 
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="text-4xl md:text-5xl font-bold text-center"
                >
                    About Us
                </motion.h1>
                <motion.p 
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    className="text-lg md:text-xl mt-4 text-center"
                >
                    Discover our mission, vision, and community.
                </motion.p>
            </section>

            {/* Mission Section */}
            <section className="py-16 px-4 text-center bg-gray-100">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">Our Mission</h2>
                    <p className="text-gray-600 mt-4 leading-relaxed">
                        At Forever His, we strive to create a Christ-centered community where everyone feels welcome and loved. Our mission is to glorify God by connecting people to His Word and equipping them to live out their faith.
                    </p>
                </div>
            </section>

            {/* Team Section */}
            <section className="py-16 px-4">
                <h2 className="text-2xl md:text-3xl font-semibold text-center text-gray-800">Meet Our Team</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 max-w-6xl mx-auto">
                    <div className="text-center">
                        <img src="https://via.placeholder.com/150" alt="Team Member" className="w-40 h-40 mx-auto rounded-full shadow" />
                        <h3 className="text-lg font-semibold mt-4">John Doe</h3>
                        <p className="text-gray-600">Lead Pastor</p>
                    </div>
                    <div className="text-center">
                        <img src="https://via.placeholder.com/150" alt="Team Member" className="w-40 h-40 mx-auto rounded-full shadow" />
                        <h3 className="text-lg font-semibold mt-4">Jane Smith</h3>
                        <p className="text-gray-600">Youth Leader</p>
                    </div>
                    <div className="text-center">
                        <img src="https://via.placeholder.com/150" alt="Team Member" className="w-40 h-40 mx-auto rounded-full shadow" />
                        <h3 className="text-lg font-semibold mt-4">Emily Johnson</h3>
                        <p className="text-gray-600">Worship Coordinator</p>
                    </div>
                </div>
            </section>

            {/* Vision Section */}
            <section className="py-16 px-4 text-center bg-gray-100">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">Our Vision</h2>
                    <p className="text-gray-600 mt-4 leading-relaxed">
                        To see lives transformed through the power of Christ, creating a community that reflects His love and grace. We are committed to making a lasting impact in our local and global communities by sharing the hope of the Gospel.
                    </p>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default About;