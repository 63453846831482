import React from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

const Events = () => {
    const events = [
        {
            name: "Christmas Celebration",
            description: "Join us for a joyful celebration of Christmas with music, fellowship, and refreshments. Bring your friends and family to share in the holiday spirit!",
            date: "December 23, 2024",
            time: "6:30 PM",
            location: "123 Fellowship Street, Hometown, USA",
        },
        {
            name: "Caroling at Assisted Living",
            description: "Spread holiday cheer by caroling at the local assisted living facility. This is a wonderful opportunity to brighten someone's day!",
            date: "December 16, 2024",
            time: "4:00 PM",
            location: "456 Care Avenue, Hometown, USA",
        },
    ];

    return (
        <div className="flex flex-col min-h-screen">
            {/* Header */}
            <Header />

            {/* Main Content */}
            <main className="flex-grow">
                {/* Hero Section */}
                <section
                    className="relative bg-cover bg-center h-96 flex flex-col justify-center items-center text-white"
                    style={{ backgroundImage: "url('https://via.placeholder.com/1920x600')" }}
                >
                    <h1 className="text-4xl md:text-5xl font-bold">Upcoming Events</h1>
                    <p className="text-lg md:text-xl mt-4 text-center">
                        Stay connected and join us for our upcoming events.
                    </p>
                </section>

                {/* Events Section */}
                <section className="py-16 px-4">
                    <div className="max-w-6xl mx-auto">
                        <h2 className="text-2xl md:text-3xl font-semibold text-center text-gray-800">Our Events</h2>
                        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
                            {events.map((event, index) => (
                                <div key={index} className="bg-white shadow rounded-lg p-6">
                                    <h3 className="text-xl font-bold text-gray-800">{event.name}</h3>
                                    <p className="text-gray-600 mt-2">{event.description}</p>
                                    <p className="text-gray-800 mt-4">
                                        <strong>Date:</strong> {event.date}
                                    </p>
                                    <p className="text-gray-800">
                                        <strong>Time:</strong> {event.time}
                                    </p>
                                    <p className="text-gray-800">
                                        <strong>Location:</strong> {event.location}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Events;
