import React from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

const Home = () => {
    return (
        <div className="home">
            {/* Navbar */}
            <Header />

            {/* Hero Section */}
            <div className="relative bg-cover bg-center h-screen flex flex-col justify-center items-center text-white" style={{ backgroundImage: "url('https://via.placeholder.com/1920x1080')" }}>
                <motion.h1 
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="text-4xl md:text-6xl font-bold text-center"
                >
                    Welcome
                </motion.h1>
                <motion.p 
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    className="text-lg md:text-xl mt-4 text-center"
                >
                    Find purpose & community at Forever His Fellowship
                </motion.p>

                <div className="mt-8 flex space-x-4">
                    <motion.button 
                        whileHover={{ scale: 1.1 }} 
                        className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 transition"
                    >
                        Plan a Visit
                    </motion.button>
                    <motion.button 
                        whileHover={{ scale: 1.1 }} 
                        className="bg-red-500 text-white px-6 py-2 rounded shadow hover:bg-red-600 transition"
                    >
                        Watch Live
                    </motion.button>
                </div>
            </div>

            {/* Services Section */}
            <section className="bg-gray-100 py-16 px-4 text-center">
                <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">Services at Forever His Fellowship</h2>
                <p className="text-gray-600 mt-4">Times, Location, & What to Expect</p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
                    <div className="bg-white shadow rounded overflow-hidden">
                        <img src="https://via.placeholder.com/400x300" alt="Placeholder" className="w-full" />
                        <div className="p-4">
                            <h3 className="text-lg font-semibold">Sunday Service</h3>
                            <p className="text-gray-600 mt-2">Join us every Sunday at 10:00 AM for worship and fellowship.</p>
                        </div>
                    </div>

                    <div className="bg-white shadow rounded overflow-hidden">
                        <img src="https://via.placeholder.com/400x300" alt="Placeholder" className="w-full" />
                        <div className="p-4">
                            <h3 className="text-lg font-semibold">Youth Ministry</h3>
                            <p className="text-gray-600 mt-2">Connecting the next generation to Christ with fun and engaging activities.</p>
                        </div>
                    </div>

                    <div className="bg-white shadow rounded overflow-hidden">
                        <img src="https://via.placeholder.com/400x300" alt="Placeholder" className="w-full" />
                        <div className="p-4">
                            <h3 className="text-lg font-semibold">Small Groups</h3>
                            <p className="text-gray-600 mt-2">Grow deeper in faith through weekly small group gatherings.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <Footer/>
        </div>
    );
};

export default Home;
