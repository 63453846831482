import React from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

const Ministries = () => {
    const ministries = [
        {
            name: "Youth Ministry",
            description: "Connecting the next generation to Christ through engaging events, small groups, and mentorship.",
            image: "https://via.placeholder.com/400x300",
        },
        {
            name: "Kids Ministry",
            description: "Creating a fun and safe environment where children can learn about God's love through stories, songs, and activities.",
            image: "https://via.placeholder.com/400x300",
        },
        {
            name: "Outreach Ministry",
            description: "Reaching out to the community with love, compassion, and the message of hope in Christ.",
            image: "https://via.placeholder.com/400x300",
        },
    ];

    return (
        <div className="flex flex-col min-h-screen">
            {/* Header */}
            <Header />

            {/* Main Content */}
            <main className="flex-grow">
                {/* Hero Section */}
                <section className="relative bg-cover bg-center h-96 flex flex-col justify-center items-center text-white" style={{ backgroundImage: "url('https://via.placeholder.com/1920x600')" }}>
                    <h1 className="text-4xl md:text-5xl font-bold">Our Ministries</h1>
                    <p className="text-lg md:text-xl mt-4 text-center">
                        Discover the many ways you can get involved and grow in faith.
                    </p>
                </section>

                {/* Ministries Section */}
                <section className="py-16 px-4">
                    <div className="max-w-6xl mx-auto">
                        <h2 className="text-2xl md:text-3xl font-semibold text-center text-gray-800">Explore Our Ministries</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
                            {ministries.map((ministry, index) => (
                                <div key={index} className="bg-white shadow rounded overflow-hidden">
                                    <img src={ministry.image} alt={ministry.name} className="w-full" />
                                    <div className="p-4">
                                        <h3 className="text-lg font-semibold">{ministry.name}</h3>
                                        <p className="text-gray-600 mt-2">{ministry.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Ministries;
